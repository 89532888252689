// Typography

@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@700&family=Roboto:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@600&family=Roboto:wght@500;700&display=swap');

$fa-font-path: '~@fortawesome/fontawesome-free/webfonts';

$sb-header-font: "Roboto Condensed", sans-serif;
$sb-body-font: "Roboto", sans-serif;

$font-family-sans-serif:      $sb-body-font;
$font-size-base:              0.9rem;
$line-height-base:            1.6;

$font-family-base:            $sb-body-font;

$headings-font-family:        $sb-header-font;
$headings-font-weight:        bold;

// Colors
$body-bg: #f8fafc;

$black: #212121;

$sb-colors: (
    sb-blue: #397890,
    sb-green: #a9c36f,
    sb-cyan: #18ad96,
    sb-orange: #f5ae3c,
    sb-red: #cd4e3d,
    sb-dark-gray: #56697c,
    sb-medium-gray: #94a3b4,
    sb-light-gray: #E9EDF0,
    sb-background-gray: #4d4d4d,
    sb-white: #ffffff,
    bfg-truth: #0099cf,
    bfg-passion: #ed549e,
    bfg-growth: #52b848,
    bfg-light: #fee70b,
);

$primary: #397890;
$secondary: #56697c;

$theme-colors: (
  "primary":    map-get($sb-colors, 'sb-blue'),
  "secondary":  map-get($sb-colors, 'sb-medium-gray'),
);

$theme-colors: map-merge($theme-colors, $sb-colors);

@function clr($key: sb-blue) {
    @return map-get($sb-colors, $key);
}

@each $name, $value in $sb-colors {
    .#{$name}-fg { color: $value; }
    .#{$name}-bg { background-color: $value; }
}

$sb-gradients: () !default;
$sb-gradients: map-merge((
    sb-com: linear-gradient(45deg, map-get($sb-colors, sb-blue) 0%, map-get($sb-colors, sb-green) 75%),
    sb-account: linear-gradient(45deg, map-get($sb-colors, sb-green) 0%, map-get($sb-colors, sb-cyan) 100%),
    sb-library: linear-gradient(45deg, map-get($sb-colors, sb-green) 0%, map-get($sb-colors, sb-cyan) 100%),

    bfg-truth-growth: linear-gradient(45deg, map-get($sb-colors, bfg-truth) 0%, map-get($sb-colors, bfg-growth) 100%),
    bfg-light-passion: linear-gradient(45deg, map-get($sb-colors, bfg-light) 0%, map-get($sb-colors, bfg-passion) 100%),
    bfg-light-growth: linear-gradient(45deg, map-get($sb-colors, bfg-light) 0%, map-get($sb-colors, bfg-growth) 100%),
    bfg-truth-passion: linear-gradient(45deg, map-get($sb-colors, bfg-truth) 0%, map-get($sb-colors, bfg-passion) 100%),
), $sb-gradients);

@mixin bg-gradient($parent, $gradient) {
    #{$parent} {
        background: $gradient;
    }
}
@each $gradient, $value in $sb-gradients {
    @include bg-gradient(".sb-gradient-#{$gradient}", $value);
}

a {
    text-decoration: none;
    color: $primary;
}
