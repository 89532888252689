@import 'variables';

.adunit {
    border: 1px solid rgba(#56697C, 0.25);
    border-radius: 2px;
    margin: 1em;
    padding: 0;
    background-color: rgba(#56697C, 0.05);
    text-align: center;
    vertical-align: middle;
    display: inline-block;
    .disclosure {
        display: none;
        color: rgba(#56697C, 0.5);
        font-size: 0.8em;
        text-transform: uppercase;
        text-align: center;
        padding-top: 0.1em;
        &:hover {
            display: block;
        }
    }
}

.adunit-native {
    margin-left: 1em;
    padding: 1em;
    width: 50%;
    border: 2px solid #94A3B4;
    background: rgba(148, 165, 180, 0.25);
    float: right;
    box-shadow: 2px 2px rgba(0, 0, 0, 0.05);
    h3 {
        text-transform: uppercase;
        font-size: 1em;
        color: #18AD96;
        font-weight: 700;
        padding: 0;
        margin: 0 0 0.5em 0;
    }
    p {
        color: #56697C;
    }
    .cta {
        text-align: right;
    }
}

.bg-sb3 {
    background-color: #CD4E3D;
}

.big-message-wrapper {
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: relative;
    background-position: center center;
    background-size: cover;
    position: relative;
    overflow: hidden;
    min-height: 350px;
    min-width: 100%;
    max-height: 100vh;
    margin: 1rem 0;
    padding: 0;

    .big-message-dimmer {
        background: rgba(#56697c, 0.75);
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        position: absolute;

        .big-message {
            border-radius: 0.25rem;
            box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);
            max-width: 500px;
            margin-left: auto;
            margin-right: auto;
            margin-top: 3.5rem;
            margin-bottom: 3.5rem;
            padding: 3rem;
            color: rgba(0, 0, 0, 0.65);
            background: rgba(255, 255, 255, 0.65);
            text-align: center;
            h4 {
                color: #397890;
            }
        }
    }
}

.bv-card {
    padding: 0.5rem;
}

.bv-channel-lede {
    padding-bottom: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
}

.bv-detail {
    background: rgba(255, 255, 255, 0.7);
    border-radius: 0 0 1rem 1rem;
    text-align: center;
    margin-bottom: 3rem;
}

.bv-header {
    display: inline;
    padding-bottom: 1rem;
}

.bv-header h1 {
    display: inline;
    background: rgba(40, 40, 40, 0.8);
    padding: 0.25rem 0.5rem;
    margin: 0;
    color: #ffffff;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.4);
    text-transform: none;
}

.bv-logo {
    padding: 1rem;
}

.bv-masthead {
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    background-size: cover;
}

.byline {
    color: #333333;
    font-size: 0.8em;
    padding-bottom: 1em;
}

.channel-name {
    text-transform: uppercase;
}

.channel-shortcard-image {
    max-width: 100px;
    padding-left: 15px;
}

.content img{
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    padding: 0.25rem;
    height: auto;
    max-width: 100%;
}

.content .lede-in {
    line-height: 140%;
    font-size: 1.8em;
    font-weight: 700;
    color: #56697C;
}

.content .lede-in p {
    line-height: 140%;
    font-size: 0.8em;
    font-weight: 700;
}

.content p {
    font-size: 17px;
    line-height: 1.6;
}

.embed-container {
    position: relative;
    overflow: hidden;
    min-width: 100%;
    min-height: 35vh;
    max-height: 100vh;
    margin: 1rem 0;
    padding: 0;
    background: rgb(50,50,50);
    background: linear-gradient(-TRy it5deg, rgb(22,22,22) 0%, rgb(70,70,70) 76%, rgb(0, 0, 0) 100%);
    iframe, object, embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 1rem;
    }
}

.h1, h1 {
    font-size: 2.25rem;
}

/*IMG "Hack" */
img[title*="img-class==img-fluid-thumbnail"] {
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    padding: 0.25rem;
    height: auto;
    max-width: 100%;
}

.link-external:after {
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    font-weight: 900;
    font-size: 0.75em;
    font-family: 'Font Awesome\ 5 Free';
    line-height: 1;
    content: "\f35d";
    margin-left: 0.5em;
    color: lighten(#F5AE3C, 20%);
    vertical-align: baseline;
}

.masthead.bar-sb1 {
    border-top: 3px solid #397890;
    color: #397890;
}

.masthead.bar-sb2 {
    border-top: 3px solid #A9C36F;
    color: #A9C36F;
}

.masthead.bar-sb7 {
    border-top: 3px solid #56697C;
    color: #56697C;
}

.masthead .channel-name a {
    color: inherit;
}

.member-block{
    background:#EAEDF0;
}

.member-block-title{
    text-transform: uppercase;
}

.story-byline p em {
    font-size: 14px;
}

.story-type-flag {
    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
    background: map-get($sb-colors, 'sb-cyan');
    padding: 5px;
    border-radius: 3px;
}

.story-channel-flag {
    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
    background: map-get($sb-colors, 'sb-green');
    padding: 5px;
    border-radius: 3px;
}

.story-hero-home, .story-hero-detail {
    color: white;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 50vh;
    margin-bottom: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-radius: 0.25em 0.25em 0 0;
}

.topics-grid {
    border: 1px solid #dee2e6;
    border-radius: .25rem;
    margin-left: 15px;
    padding-top: 25px;
}

.topics-grid-item {
    min-height: 95px;
    margin-bottom: 1rem;
}

.wg-channel-lede {
    padding-bottom: 0.15rem !important;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    line-height: 1.25 !important;
}

.wg-masthead {
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    background-size: cover;
    width: 100%;
}

@media screen and (min-width: 546px){
    .topics-grid-item {
        min-height: 68px;
    }
}

@media screen and (min-width: 634px){
    .topics-grid-item {
        min-height: 45px;
    }
}
